html {
  font-size: 16px;
}
:root {
  /* define gap(margin/padding/etc) */
  --gap-128: 128px;
  --gap-64: 64px;
  --gap-48: 48px;
  --gap-36: 36px;
  --gap-24: 24px;
  --gap-16: 16px;
  --gap-12: 12px;
  --gap-8: 8px;
  --gap-4: 4px;

  /* define font-size */
  --font-48: 48px;
  --font-36: 36px;
  --font-24: 24px;
  --font-16: 16px;
  --font-12: 12px;
  --font-8: 8px;

  /* define contents width */
  --width-desk: 1023px;
  --width-tablet: 648px;
  --width-mobile: calc(100% - 48px);
  --width-modal: 73.6111vw; /* 1060px per 1440*/
  --width-modalContents: 55.5556vw; /* 800px per 1440*/

  /* define responsive browser width */
  --tablet: 1023px;
  --mobile: 768px;

  /* define theme */

  --primary: #173aff;
  /* --primary: #00a0e9; */
  --secondary-1: #0090d2;
  --secondary-2: #80cff4;
  --secondary-3: #e6f6fd;

  --blue-01: #7e91fe;
  --blue-02: #c2cbff;
  --blue-03: #e9ecff;

  --green: #48b9ab;
  --green-01: #4db1a5;
  --green-02: #9ee3db;
  --green-03: #d7f5ee;

  --grey-00: #e5e5ea;
  --grey-10: #d1d1d6;
  --grey-20: #c7c7cc;
  --grey-30: #aeaeb2;
  --grey-40: #8e8e93;
  --grey-50: #636366;
  --grey-60: #3a3a3c;
  --black: #000000;
  --white: #ffffff;

  --bg-01: #ffffff;
  --bg-02: #fbfbfb;
  --bg-03: #f7f7f9;

  --red: #ff0000;
  --grey: rgba(123, 123, 123, 0.8);
  --green: #4bc280;
  --green1: #45b176;
  --green2: #7ddaa6;
  --green3: #dbf3e6;
  --bg-white: #fafafa;
  --bg-grey: rgba(191, 191, 191, 0.13);
  --border-grey: rgba(191, 191, 191, 0.4);
}
@font-face {
  font-family: "Bpp Black";
  src: url("./font/Proxima-Nova-Black.otf");
  font-display: fallback;
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}

@font-face {
  font-family: "Bpp Black";
  src: url("./font/Pretendard-Black.woff2") format("woff2");
  src: url("./font/Pretendard-Black.woff") format("woff");
  font-display: fallback;
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: "Bpp Bold";
  src: url("./font/Proxima-Nova-Bold.otf");
  font-display: fallback;
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}

@font-face {
  font-family: "Bpp Bold";
  src: url("./font/Pretendard-Bold.woff2") format("woff2");
  src: url("./font/Pretendard-Bold.woff") format("woff");
  font-display: fallback;
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: "BPP Regular";
  src: url("./font/Proxima-Nova-Regular.otf");
  font-display: fallback;
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}

@font-face {
  font-family: "BPP Regular";
  src: url("./font/Pretendard-Regular.woff2") format("woff2");
  src: url("./font/Pretendard-Regular.woff") format("woff");
  font-display: fallback;
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: "BPP Semibold";
  src: url("./font/Proxima-Nova-Semibold.otf");
  font-size: 18px;
  letter-spacing: 0em;
  font-display: fallback;
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}

@font-face {
  font-family: "BPP Semibold";
  src: url("./font/Pretendard-SemiBold.woff2") format("woff2");
  src: url("./font/Pretendard-SemiBold.woff") format("woff");
  font-size: 18px;
  letter-spacing: -0.02em;
  font-display: fallback;
  unicode-range: U+AC00-D7A3;
}

@font-face {
  font-family: "Bpp Light";
  src: url("./font/Proxima-Nova-Light.otf");
  font-display: fallback;
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039;
}

@font-face {
  font-family: "Bpp Light";
  src: url("./font/Pretendard-Light.woff2") format("woff2");
  src: url("./font/Pretendard-Light.woff") format("woff");
  font-display: fallback;
  unicode-range: U+AC00-D7A3;
}
body {
  background-color: var(--white);
  overflow-y: scroll;
  overflow-x: hidden;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
body a {
  width: fit-content;
}

body::-webkit-scrollbar {
  display: none;
}

body.ReactModal__Body--open {
  overflow: hidden !important;
}

.scroll__invisible {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scroll__invisible::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.limit__body {
  width: 100%;
  max-width: calc(1176px + 264px);
  margin: 0 auto;
  padding: 0 132px;
}

.font-title-lx {
  font-family: "Bpp Semibold";
  font-size: 48px;
  letter-spacing: -0.02em;
}

.font-title-lx-en {
  font-family: "Bpp Semibold";
  font-size: 48px;
  letter-spacing: 0em;
}

.font-title {
  font-family: "Bpp Bold";
  font-size: 34px;
  letter-spacing: -0.02em;
}

.font-title-en {
  font-family: "Bpp Bold";
  font-size: 40px;
  letter-spacing: 0em;
}

.font-sub-title {
  font-family: "Bpp Bold";
  font-size: 28px;
  letter-spacing: -0.02em;
}
.font-sub-title-en {
  font-family: "Bpp Bold";
  font-size: 28px;
  letter-spacing: 0em;
}
.font-sub-title2 {
  font-family: "Bpp Bold";
  font-size: 24px;
  letter-spacing: -0.02em;
}
.font-sub-title2-en {
  font-family: "Bpp Bold";
  font-size: 24px;
  letter-spacing: 0em;
}
.font-sub-title3 {
  font-family: "Bpp Bold";
  font-size: 20px;
  letter-spacing: -0.02em;
}
.font-sub-title3-en {
  font-family: "Bpp Bold";
  font-size: 20px;
  letter-spacing: 0em;
}
.font-sub-title4 {
  font-family: "Bpp Bold";
  font-size: 18px;
  letter-spacing: -0.02em;
}
.font-sub-title4-en {
  font-family: "Bpp Bold";
  font-size: 18px;
  letter-spacing: 0em;
}

.font-body {
  font-family: "Bpp Regular";
  font-size: 28px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-body-en {
  font-family: "Bpp Regular";
  font-size: 28px;
  letter-spacing: 0em;
  line-height: 150%;
}

.font-body1 {
  font-family: "Bpp Regular";
  font-size: 24px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-body1-en {
  font-family: "Bpp Regular";
  font-size: 24px;
  letter-spacing: 0em;
  line-height: 150%;
}

.font-body2 {
  font-family: "Bpp Regular";
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-body2-en {
  font-family: "Bpp Regular";
  font-size: 18px;
  letter-spacing: 0em;
  line-height: 150%;
}

.font-body3 {
  font-family: "Bpp Regular";
  font-size: 16px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-body3-en {
  font-family: "Bpp Regular";
  font-size: 16px;
  letter-spacing: 0em;
  line-height: 150%;
}

.font-body4 {
  font-family: "Bpp Regular";
  font-size: 14px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-body4-en {
  font-family: "Bpp Regular";
  font-size: 14px;
  letter-spacing: 0em;
  line-height: 150%;
}

.font-small-text {
  font-family: "Bpp Regular";
  font-size: 12px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-small-text-en {
  font-family: "Bpp Regular";
  font-size: 12px;
  letter-spacing: 0em;
  line-height: 150%;
}

.font-button1 {
  font-family: "Bpp Semibold";
  font-size: 18px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-button1-en {
  font-family: "Bpp Semibold";
  font-size: 18px;
  letter-spacing: 0em;
  line-height: 150%;
}

.font-button2 {
  font-family: "Bpp Semibold";
  font-size: 16px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-button2-en {
  font-family: "Bpp Semibold";
  font-size: 16px;
  letter-spacing: 0em;
  line-height: 150%;
}

.font-button3 {
  font-family: "Bpp Semibold";
  font-size: 14px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-button3-en {
  font-family: "Bpp Semibold";
  font-size: 14px;
  letter-spacing: 0em;
  line-height: 150%;
}

/* define text in a use */

.Text-label {
  font-family: "Bpp Medium";
  font-size: calc(var(--font-8) + 0.5556vmin);
  letter-spacing: -0.02em;
  line-height: 150%;
}

.Text-title {
  font-family: "Bpp Medium";
  font-size: calc(var(--font-16) + 0.5556vmin);
  letter-spacing: -0.02em;
  line-height: 150%;
}

.Text-desc {
  font-family: "Bpp Regular";
  font-size: calc(var(--font-12) + 0.2778vmin);
  letter-spacing: -0.02em;
  line-height: 150%;
}

.Text-news {
  font-family: "Bpp Regular";
  font-size: calc(var(--font-12) + 0.2778vmin);
  letter-spacing: -0.05em;
  line-height: 150%;
}

.Text-link {
  font-family: "Bpp Medium";
  font-size: calc(var(--font-12) + 0.2778vmin);
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-bold-48 {
  font-family: "Bpp Bold";
  font-size: 48px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-bold-40 {
  font-family: "Bpp Bold";
  font-size: 40px;
  line-height: 150%;
}

.font-bold-34 {
  font-family: "Bpp Bold";
  font-size: 34px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-bold-30 {
  font-family: "Bpp Bold";
  font-size: 30px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-bold-28 {
  font-family: "Bpp Bold";
  font-size: 28px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-bold-26 {
  font-family: "Bpp Bold";
  font-size: 26px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-bold-24 {
  font-family: "Bpp Bold";
  font-size: 24px;
  letter-spacing: -0.02em;
  line-height: 150%;
}
.font-bold-22 {
  font-family: "Bpp Bold";
  font-size: 22px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-bold-20 {
  font-family: "Bpp Bold";
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-bold-18 {
  font-family: "Bpp Bold";
  font-size: 18px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-bold-16 {
  font-family: "Bpp Bold";
  font-size: 16px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-bold-14 {
  font-family: "Bpp Bold";
  font-size: 14px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-bold-12 {
  font-family: "Bpp Bold";
  font-size: 12px;
  letter-spacing: -0.02em;
  line-height: 150%;
}
.font-regular-34 {
  font-family: "Bpp Regular";
  font-size: 34px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-regular-30 {
  font-family: "Bpp Regular";
  font-size: 30px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-regular-28 {
  font-family: "Bpp Regular";
  font-size: 28px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-regular-24 {
  font-family: "Bpp Regular";
  font-size: 24px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-regular-22 {
  font-family: "Bpp Regular";
  font-size: 22px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-regular-20 {
  font-family: "Bpp Regular";
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-regular-18 {
  font-family: "Bpp Regular";
  font-size: 18px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-regular-16 {
  font-family: "Bpp Regular";
  font-size: 16px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-regular-14 {
  font-family: "Bpp Regular";
  font-size: 14px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-regular-12 {
  font-family: "Bpp Regular";
  font-size: 12px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-regular-8 {
  font-family: "Bpp Regular";
  font-size: 8px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-medium-48 {
  font-family: "Bpp Medium";
  font-size: 48px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-medium-36 {
  font-family: "Bpp Medium";
  font-size: 36px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-medium-26 {
  font-family: "Bpp Medium";
  font-size: 26px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-medium-24 {
  font-family: "Bpp Medium";
  font-size: 24px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-medium-22 {
  font-family: "Bpp Medium";
  font-size: 22px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-medium-20 {
  font-family: "Bpp Medium";
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-medium-18 {
  font-family: "Bpp Medium";
  font-size: 18px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-medium-16 {
  font-family: "Bpp Medium";
  font-size: 16px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-medium-14 {
  font-family: "Bpp Medium";
  font-size: 14px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-medium-12 {
  font-family: "Bpp Medium";
  font-size: 12px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-medium-10 {
  font-family: "Bpp Medium";
  font-size: 10px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-bold-18 {
  font-family: "Bpp Bold";
  font-size: 18px;
  letter-spacing: -0.05em;
  line-height: 150%;
}

.font-light-48 {
  font-family: "Bpp Light";
  font-size: 48px;
  letter-spacing: -0.02em;
  line-height: 1;
}

.font-light-18 {
  font-family: "Bpp Light";
  font-size: 18px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

.font-light-12 {
  font-family: "Bpp Light";
  font-size: 12px;
  letter-spacing: -0.02em;
  line-height: 150%;
}

@media only screen and (max-width: 768px) {
  .limit__body {
    padding: 0 24px;
  }
}
@keyframes shake {
  25% {
    transform: translateX(4px);
  }
  50% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(4px);
  }
}
